.FormItemUpload {
  .FormItemUpload-load {
    display: flex;
    align-items: center;
    position: relative;
    .info-file {
      position: absolute;
      left: 240px;
      top: 6px;
    }
  }
  .ant-form-item-label {
    // width: 200px;
  }
}
