.ant-tabs-nav {
  padding-left: 30px;
  background-color: #fff;
  height: 30px;
  .ant-tabs-tab-btn {
    font-size: 16px;
  }
}

.PlatformSetupApplyDetail_Content {
  width: 100%;
  min-height: 720px;
  background: #fff;

  .PlatformSetupApplyDetail_Content_basie {
    width: 100%;

    padding: 24px;
  }
}

.ant-tabs-nav-list {
  padding-bottom: 10px;
}
