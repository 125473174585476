.NewTradingScheme_Content_button_save {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding-right: 20px;
  height: 56px;
  background: #fff;
  margin-top: 10px;
  align-items: center;
  justify-content: center;

  .NewTradingScheme_Content_button_save_left {
    margin-right: 10px;
  }
}
.inform-action {
  position: relative;
  min-height: 700px;
}
.registration {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -100px;
  .ant-form-item-label {
    width: 200px;
  }
}
