.login {
  position: relative;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  .sign-container {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 500px;
    justify-content: center;
    width: 500px;
    &-text {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      width: 100%;
      img {
        height: 86px;
        width: 80px;
      }
      h2 {
        padding-top: 15px;
      }
    }
    &-input {
      margin-left: 165px;
    }
    .ant-tabs {
      height: 400px;
      width: 400px;
    }
    .ant-tabs-nav-wrap {
      justify-content: center;
    }
    .ant-tabs-nav {
      margin-bottom: 25px;
      border-bottom: none;
      width: 250px;
    }
    input.ant-input {
      width: 200px;
    }
  }
  .login_footer {
    position: absolute;
    bottom: 10px;
    left: 48%;
    font-family: PingFang SC;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(158, 158, 158, 1);
  }
}
