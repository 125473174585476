.template_container {
  padding: 20px 23px;
  background-color: #f0f2f5;
  width: calc(100% - 300px);
  height: calc(100vh - 50px);
  overflow: hidden;
  .content {
    padding: 20px;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }

  .create_button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .config_step {
    padding-left: 10%;
    padding-right: 10%;
  }

  .template_description_input {
    padding: 50px 60% 20px 0;
  }

  .template_steps_content {
    display: flex;
    flex-wrap: wrap;
  }

  .template_steps_content .steps {
    display: block;
  }

  .step_description {
    width: 25%;
    padding-top: 20px;
  }

  .button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ant-btn {
    margin-top: 10px;
  }
}

.ant-steps-item-description {
  max-width: 100% !important;
}
.ant-pagination {
  padding-top: 16px;
}
