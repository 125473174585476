.supply_order_container {
  height: calc(100vh - 50px);

  background-color: #f0f2f5;

  .title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 0 16px 24px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }
  .info_values {
    padding: 24px;
    .info_items {
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
    }
  }
  .mar_top_16 {
    margin-top: 8px;
  }
}
.details_type {
  padding-top: 16px;
}
.detail_span {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
  padding-left: 10px;
}
.supply_order_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  color: rgba(0, 0, 0, 0.51);
}
.value {
  padding-left: 8px;
}

.modalList {
  margin-left: 34px;
  width: 85%;
  height: 365px;
  overflow-y: scroll;
}
.rateSetting_order {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-bottom: 16px;
  .rateSetting_order_text {
    // width: 250px;
    height: 32px;
    background: #FFFBEB;
    border: 1px solid #FAAD1459;
    text-align: center;
    line-height: 32px;
    color: #FF7300D9;
  }
}

.rateSetting_text {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
