.trajectory-map {
  .trajectory-map-header {
    height: 26px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;
    p {
      margin: 0;
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(230 247 255 / 100%);
        border: 1px solid rgb(145 213 255 / 100%);
        border-radius: 2px;
        p {
          width: 16px !important;
          height: 16px;
          margin: 0;
          margin-right: 8px;
          font-size: 13px;
          color: white;
          cursor: pointer;
          background: rgb(24 144 255 / 100%);
          border: 1px solid rgb(230 247 255 / 100%);
          border-radius: 50%;
        }
      }
    }
  }
}
