.id_desc {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  padding-top: 10px;
}
.needName-form {
  display: flex;
  align-items: center;
  p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
