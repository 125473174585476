.wrapper {
  padding: 24px 18px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  margin: 0;

  .cardList {
    display: flex;
    background: #f0f2f5;
  }

  .lineEcharts {
    margin-top: 16px;
    display: flex;
  }

  .barEcharts {
    margin-top: 16px;
  }
}
