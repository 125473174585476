.entirty_list_container {
  width: calc(100% - 208px);
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 0 20px 20px 20px;
  .entirty_search_container {
    padding: 20px 20px 10px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  .entirty_table_container {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    .entirty_table_nav_flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }
  .mar_r_8 {
    margin-right: 8px;
  }
}

.evidence-container {
  width: 100vw;
  height: 100vh;

  &-content {
    height: calc(100% - 48px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}
