.offline-receipt-sub-title {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.offline-receipt-desc {
  position: relative;
  .ant-descriptions-item-label {
    color: #7d7d7d !important;
  }
  .ant-descriptions-item {
    padding-bottom: 0;
  }
  .offline-text {
    position: absolute;
    left: 10px;
    top: 5px;
    font-weight: 500;
    font-size: 24px;
  }
}

.offline-receipt-content {
  padding: 16px 24px 16px 24px;
}
.payment_amount {
  position: relative;

  .amount_num {
    position: absolute;
    top: 5px;
    right: 70px;
    color: red;
  }
}
