.ipt {
  width: 200px;
  margin-right: 10px;
  .ipt_num {
    width: 150px;
  }
  .ipt_col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 200px;
    margin-left: 40px;
    width: 400px;
  }
}
.user-crud {
  position: relative;

  .user-crud-span {
    position: absolute;
    top: 5px;
    left: 200px;
  }
}
.span_color {
   color:rgba(0, 0, 0, 0.45);
}
