.platform_setup_apply {
  width: calc(100vw - 208px);
  height: 100%;
  padding: 20px;
  display: block;
  position: relative;
  background-color: #f0f2f5;
  .content {
    background-color: #fff;
    height: calc(100vh - 100px);
    padding: 20px;
  }

  &-add {
    margin-bottom: 15px;
  }

  .system-user-add {
    display: flex;
    justify-content: flex-end;
  }
}

.PlatformSetupApply_Content_user {
  width: 100%;
  background: #fff;
  .PlatformSetupApply_Content_basie {
    width: 100%;

    padding: 16px 24px 16px 24px;
    .PlatformSetupApply_Content_basic_div {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-pagination {
    margin-top: 11px;
  }
}
