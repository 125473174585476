.agency_step_container{
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 16px;
    .agency_step_container_title{
        padding: 16px 0 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        box-shadow: inset 0px -1px 0px #E9E9E9;
    }
    .agency_steps{
        padding: 24px;
    }
    .agency_step_opera{
        padding: 0 24px 24px;
        display: flex;
        justify-content: space-between;
        .agency_step_status{

            color: rgba(0, 0, 0, 0.85);
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            .default_bg{
                background: #FEF7E7;
                border: 1px solid #FDEECE;
                border-radius: 2px;
                // width: 114px;
                padding: 0 8px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 16px;
                .default_color{
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #FAAB0C;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }
        .right_btn_operation{
            display: flex;
            .mar_right{
                margin-right: 8px;
            }
        }
    }
}
.reject_modal{
  .ant-modal-footer {
    padding:  0 16px 16px 0 ;
  }
  .reject_modal_container{
      display: flex;
      padding: 16px;
      .reject_form_label{
          width: 120px;
          text-align: right;
      }
      .reject_form_value{
          width: 320px;
      }

  }
}
.agency_step_opera {
  position: relative;
}
.step_tax {
  position: absolute;
  top: -20px;
  left: 32.2%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

