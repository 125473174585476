.ipt {
  width: 400px;
  margin-right: 10px;
}
.select_ipt {
  display: flex;
  justify-content: flex-start;
  margin-left: 70px;
  width: 800px;
  min-height: 40px;
}
.select-add {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.user-crud {
  position: relative;

  .user-crud-span {
    position: absolute;
    top: 5px;
    left: 200px;
  }
}
.span_color {
  color: rgba(0, 0, 0, 0.45);
}
