.export_inStorage_modal {
  .exportType {
    width: 100%;
    height: 20px;
    padding: 0px 20px;
  }
  .CheckAll {
    padding: 20px;
  }
  .Check-list {
    padding: 20px;
  }
}
.export_excel_modal {
  .export_excel_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    p {
      margin-bottom: 40px;
      font-weight: bold;
    }
  }
}
