.info_bank {
  display: flex;
}
.bank_top {
  padding-top: 16px;
}
.only_read {
  color: rgba(0, 0, 0, 0.45);
}
.onlyRead_left {
  width: 500px;
}
.evidence_config_list {
  width: 100%;
  .tr-toolbar {
    height: 0 !important;
  }
  .tr-table-wrapper {
    padding: 0 !important;
  }
}
