.marker-online{
  width: 20px;
  height: 30px;
  background-image: url('../../../../assets/data/online.png');
  background-size: cover;
}

.marker-off{
  width: 20px;
  height: 30px;
  background-image: url('../../../../assets/data/off.png');
  background-size: cover;
}
.map-container {
  position: relative;
  .map-charts {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 100000;
  }
}
.map-charts {
  width: 78px;
  height: 75px;
  background: rgba(7, 6, 33, 0.8);
  padding-top: 7px;
  padding-left: 10px;
  .map-charts-online {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    span {
      padding-left: 5px;
    }
  }
  .map-charts-off {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    span {
        padding-left: 5px;
      }
  }
}
