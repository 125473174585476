.store-wrapper {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  display: flex;
  flex-direction: column;

  .page-header {
    width: 100%;
    height: 48px;
    background: #001529;
    display: flex;
    align-items: center;

    .logo {
      width: 270px;
      color: white;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      > p {
        margin: 0;
      }

      > img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }

    .nav_list_content {
      color: #fff;
      width: 120px;
      margin-left: -20px;
      text-align: center;
      border-bottom: 2px solid #1890ff;
      padding: 12px 0;
      font-size: 14px;
    }
  }

  .page-padding {
    padding: 0 208px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .store-header {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      background: white;
      margin-bottom: 24px;
      padding: 16px 24px;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
      }

      .store-header-t {
        .page-title {
          font-size: 24px;
          font-weight: 550;
        }

        .store-status {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #00d18b;
            margin: 0 6px 0 8px;
          }
        }
      }

      .store-header-b {
        .score {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .store-content {
      background: white;
      flex: 1;

      .store-content-title {
        font-size: 18px;
        font-weight: 550;
        border-bottom: 1px solid #e9e9e9;
        padding: 18px 24px;
      }

      .ant-row {
        margin: 36px 0;

        .ant-col {
          display: flex;

          & > span {
            color: rgba(0, 0, 0, 0.45);
            display: inline-block;
            min-width: 160px;
            text-align: right;
            padding-right: 8px;
          }

          & > span::after {
            content: "：";
          }

          > div {
            flex: 1;
          }
        }

        .ant-col-24 {
          display: flex;
        }
      }

      .img-view {
        .ant-spin-container {
          display: none;
        }
      }
    }

    .modal {
      background: white;
      bottom: 10%;
      right: 3%;
      position: fixed;
      width: 240px;
      height: 320px;
      border-radius: 8px;
      box-shadow: -1px 1px 10px #888888;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;

      button {
        margin-top: 18px;
      }
    }

    img {
      width: 200px;
      height: auto;
    }
  }

  @media (max-width: 1280px) {
    .page-padding {
      padding: 0 108px;
    }
  }
}
