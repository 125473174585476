.ant-descriptions-item-content {
  display: flex;
  align-items: center;
}

.ant-space-item {
  .anticon-edit {
    color: rgba(154, 166, 184, 1);
  }
}
.tissue-radio {
  .ant-radio-button-wrapper {
    margin: 8px 4px 0;
  }
}
