.detail-page-header {
  background-color: #fff;
  padding: 16px 32px 10px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &-title {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .detail-page-header-pane {
    display: flex;
    justify-content: space-between;
  }
  .detail-page-header-right-content {
    text-align: right;
  }
}
