.businessLicense {
  width: 239px;
  background-image: url("../../../../assets/businessLicense.png");
  background-repeat: no-repeat;
  background-size: 239px 162px;

  .ant-upload {
    width: 220px !important;
    height: 150px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background-color: rgb(0 0 0 / 0%) !important;
  }

  .actionStyle {
    position: absolute;
    top: 42%;
    left: 60%;
  }
}
.idCardFront {
  background-image: url("../../../../assets/idCardFront.png");
  background-repeat: no-repeat;
  background-size: 178px 121px;
  .ant-upload {
    width: 158px !important;
    height: 110px !important;
    border: none !important;
    background-color: rgb(0 0 0 / 0%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .actionStyle {
      position: absolute;
      top: 40%;
      left: 62%;
    }
}
.idCardBack {
  background-image: url("../../../../assets/idCardBack.png");
  background-repeat: no-repeat;
  background-size: 178px 121px;
  .ant-upload {
    width: 158px !important;
    height: 110px !important;
    border: none !important;
    background-color: rgb(0 0 0 / 0%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .actionStyle {
      position: absolute;
      top: 40%;
      left: 62%;
    }
}


.operableImage {
  // display: flex;
  align-items: center;
	.operableImage-payment {
		width: 450px;
		display: flex;
		flex-wrap: wrap;
	}
}
