ul,
li {
  list-style: none;
  padding: 0;
}
.level-info li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  p {
    margin: 0;
    padding-left: 20px;
    font-size: 12px;
    font-style: PingFang SC;
    font-weight: 600;
    color: #333;
  }
}
