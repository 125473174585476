.orderPayment {
  .ant-form-item-control-input {
    min-width: 400px;
    padding-top: 30px;
    display: flex;
    align-items: center;
  }
  .ant-col .ant-form-item-control {
    margin-top: -25px;
    margin-left: 10px;
  }
}
.NewTradingScheme_Collapse_orderPayment {
  color: rgba(0, 0, 0, 0.45);
  padding-left: 10px;
}
.NewTradingScheme_Collapse_maxDifferenceRatio {
  display: flex;
  align-items: center;
}
.ant-collapse-item .ant-collapse-item-active {
  border-radius: 0px;
  border: none;
}
