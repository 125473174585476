.left-nav-bar {
  width: 208px;
  height: 100%;
  > ul {
    height: 100%;
  }
}
.meun-children {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
