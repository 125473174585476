.form_item_container {
  padding-top: 16px;
}
.options_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin:20px 60px 20px 0;
}
.ant-form-item-control-input-content {
  .ant-picker-range {
    width: 100%;
  }
}
