.procure-order-information-bar {
  height: 40px;
  margin-top: 0;
  line-height: 40px;
  border-radius: 2px;
  background: rgba(24, 144, 255, 0.1);
  border: 1px solid rgba(24, 144, 255, 0.5);
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.purchase-order-page {
  .ant-pro-table-alert-info {
    color: #000;
  }
  .ant-pro-table-alert {
    background-color: transparent;
  }
  .ant-pro-table-alert-container {
    padding-block: 0;
    padding-inline: 0;
  }
  .ant-pro-table-alert-info-option {
    display: none;
  }
}
