.manage_step_container {
  width: 300px;
  .manage_step {
    display: flex;
    align-items: center;
    .manage_step_line {
      flex: 1;
      border: 1px solid #999;
    }
  }
}
