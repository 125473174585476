.customer_info_tabs {
  margin-top: 20px;
  .customer_tabs_content {
    background-color: #fff;

    overflow-y: auto;
    padding: 20px;
    min-height: 200px;
  }
}
.cus_container {
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 20px;
  background-color: #f0f2f5;
}
.NewUserDetail_Content_basic {
  width: 100%;
  height: 136px;
  background: #fff;
  .ant-space-item {
    .anticon {
      color: rgba(154, 166, 184, 1);
    }
  }
  .NewUserDetail_Content_basic_title {
    height: 56px;
    padding: 20px 24px 0 24px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);

    p {
      height: 24px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .NewUserDetail_Content_basic_input {
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    padding: 24px;
    align-items: center;
    .userDetail_name {
      position: absolute;
      left: 92px;
      top: -125px;
      .ant-space-item {
        font-size: 20px;
        font-weight: 500;
        .anticon {
          color: rgba(154, 166, 184, 1);
        }
      }
    }
  }
}
.NewUserDetail_Content_settlement {
  width: 100%;
  min-height: 300px;
  background: #fff;

  .NewUserDetail_Content_settlement_title {
    padding: 24px 24px 0 24px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    display: flex;
    justify-content: space-between;
    p {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 500;
    }
  }
  .NewUserDetail_Content_settlement_input {
    width: 100%;
    padding: 24px 24px 16px 24px;
  }
}
.NewUserDetail_password {
  display: flex;
  align-items: center;
  a {
    padding-left: 10px;
  }
}

.ant-modal-footer {
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
}
