.new-order-detail-page {
  &-status-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 8px;
    .ant-tag {
      margin-right: 0;
    }
  }
  &-status-label {
    white-space: nowrap;
    font-size: 14px !important;
    line-height: 28px;
    color: #00000073 !important;
  }
}
