.large-screen {
  width: 100%;
  min-height: 700px;
  padding: 10px;
  background: #fff;
  .ant-card {
    width: 350px;
    height: 310px;
  }
  .ant-card-body {
    padding: 10px;
  }
  .large-text {
    font-weight: 500;
    font-size: 18px;
  }
}
