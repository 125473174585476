.billing-add {
  padding-top: 16px;
}

.procure-order-information {
  height: 40px;
  margin-top: 16px;
  line-height: 40px;
  border-radius: 2px;
  background: rgba(24, 144, 255, 0.1);
  border: 1px solid rgba(24, 144, 255, 0.5);
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  .procure-span {
    padding-left: 10px;
  }
}

.ant-content {
  padding: 16px 24px;
}
