.apply-radio {
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.apply-billing {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.apply-content {
  padding-top: 24px;
  margin-bottom: 16px;
}
.ant-content {
  padding: 16px 24px;
}
.info_items {
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}
.label {
  color: rgba(0, 0, 0, 0.51);
}

.value {
  padding-left: 8px;
}

.apply-billing-row {
  padding-top: 24px;
  margin-bottom: 10px;
}
.order-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .apply-billing-button {
    margin-top: 24px;
    color: rgba(24, 144, 255, 1);
  }
}

.text {
  height: 30px;
  border-radius: 2px;
  line-height: 30px;
  background: rgba(255, 251, 230, 1);
  border: 1px solid rgba(255, 229, 143, 1);
  color: rgba(0, 0, 0, 0.85);
  padding: 0 10px;
  margin-top: 24px;
}
.table {
  margin-bottom: 10px;
  .tr-toolbar {
    margin-bottom: -50px;
  }
  .tr-table-wrapper {
    padding: 0 !important;
  }
}
