.template_step_descriptions {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .step_descriptions {
    width: 230px;
    margin-left: 15px;
    .step_index {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .step_label {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: 1px solid rgba(24, 144, 255, 1);
        border-radius: 50%;
        color: rgba(24, 144, 255, 1);
      }

      .step_line {
        display: inline-block;
        border-top: 1px solid rgba(240, 240, 240, 1);
        width: calc(100% - 20px);
      }
    }
  }
}
.detail-page-header-left-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.config_step {
  width: 100%;
  height: 50px;
  margin-top: 30px;
}
.template_primary {
  width: 100%;
  height: 50px;
  background: #fff;
  margin-top: 10px;
  line-height: 50px;
}
.template_steps_content {
  width: 100%;
}
.create_button {
  display: flex;
}
