.video-surveillance {
  width: 100%;
  height: 100%;
  background: rgba(5, 20, 57, 1);
  .video-header {
    position: relative;
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: space-between;
    .video-header-time {
      position: absolute;
      left: 10px;
      top: 20px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(232, 239, 253, 1);
    }
    img {
      margin-top: -30px;
    }
  }
  .video-content {
    width: 100%;
    height: 630px;
    display: flex;
    .video-content-left {
    width: 28%;
      // width: 520px;
      height: 100%;
    .video-content-left-up {
      position: relative;
       width: 100%;
       height: 150px;
       color: #fff;
       img {
          width: 100%;
          height: 150px;
       }
       .video-icon {
        position: absolute;
        left: 8px;
        top: 6px;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        span {
          color: rgba(240, 255, 255, 1);
          font-size: 22px;
          padding: 10px;
        }
       }
       .video-content-left-up-count {
          width: 100%;
          position: absolute;
          left: 40px;
          top: 75px;
          div {
            width: 80%;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
          }
       }
     }
      .video-content-left-the {
        position: relative;
        width: 100%;
        height: 150px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 150px;
        }
        .video-icon {
          position: absolute;
          left: 8px;
          top: 6px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            color: rgba(240, 255, 255, 1);
            font-size: 22px;
            padding: 10px;
          }
        }
        .video-content-left-the-allCount {
          position: absolute;
          right: 35px;
          top: 5px;
          font-size: 40px;
          font-weight: 700;
          color: rgba(77, 228, 255, 1);
          span {
            font-weight: 400;
            font-size: 18px;
            color: rgba(0, 160, 233, 1);
          }
        }
        .video-content-left-the-count {
          width: 100%;
          position: absolute;
          color: #fff;
          left: 40px;
          top: 75px;
          div {
            width: 80%;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      .video-content-left-under {
        position: relative;
        width: 100%;
        height: 300px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 300px;
        }
        .video-icon {
          position: absolute;
          left: 8px;
          top: 6px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            color: rgba(240, 255, 255, 1);
            font-size: 22px;
            padding: 10px;
          }
        }
        .video-content-left-under-text {
          position: absolute;
          display: flex;
          right: 40px;
          top: 20px;
          font-size: 18px;
          font-weight: 700;
          color: rgba(127, 207, 244, 1);
          z-index: 999;
          cursor: pointer;
        }
        .video-content-left-under-charts {
           position: absolute;
           left: 20px;
           top: 40px;
           color: #fff;
            z-index: 999;
           .echarts-for-react {
            z-index: 999;
           }
        }
      }
    }
    .video-content-context {
      position: relative;
      // width: 110%;
      width: 46%;
      height: 100%;
      margin-left: 15px;
      img {
        width: 100%;
        height: 630px;
      }
      .video-icon {
        position: absolute;
        left: 38.3%;
        top: 18px;
        display: flex;
        align-items: center;
        span {
          color: rgba(240, 255, 255, 1);
          font-size: 22px;
          // padding: 10px;
        }
      }
      .video-content-context-map {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 100%;
        height: 600px;
      }
    }
    .video-content-right {
    width: 28%;
      height: 100%;
      margin-left: 15px;
      .video-content-right-under {
        position: relative;
        width: 100%;
        height: 310px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 310px;
        }
        .video-icon {
          position: absolute;
          left: 8px;
          top: 6px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            color: rgba(240, 255, 255, 1);
            font-size: 22px;
            padding: 10px;
          }
        }
        .video-content-right-under-charts {
           position: absolute;
           left: 10px;
           top: 50px;
           width: 100%;
           height: 330px;
           z-index:1000;
        }
      }
      .video-content-right-up {
        position: relative;
        width: 100%;
        height: 300px;
        img {
          width: 100%;
          height: 305px;
        }
        .video-icon {
          position: absolute;
          left: 8px;
          top: 6px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            color: rgba(240, 255, 255, 1);
            font-size: 22px;
            padding: 10px;
          }
        }
        .video-content-right-up-city {
          width: 100%;
          position: absolute;
          left: 40px;
          top: 60px;
          .video-content-right-up-city-downtown {
            width: 88%;
            display: flex;
            height: 40px;
            line-height: 40px;
            justify-content:space-between;
            background-color: rgba(4, 50, 114, 1);
            padding: 0 10px;
            p {
              color: rgba(255, 255, 255, 0.75);
              font-size: 16px;
            }
          }
          .video-content-right-up-city-list {
            .video-content-right-up-city-list-ranking {
              width: 88%;
              display: flex;
              color: #fff;
              justify-content: space-between;
              border-bottom: 1px solid rgba(58, 67, 92, 1);
              padding-top: 7px;
            }
          }
        }
      }
    }
  }
  .video-footer {
    position: relative;
    width: 100%;
    height: 250px;
    img {
      width: 100%;
      height: 250px;
    }
      .video-icon {
        position: absolute;
        left: 8px;
        top: 6px;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }

        span {
          color: rgba(240, 255, 255, 1);
          font-size: 22px;
          padding: 10px;
        }
      }
    .video-footer-player {
      position: absolute;
      left: 40px;
      top: 60px;
      display: flex;
      color: #fff;
      .video-footer-player-delta {
        width: 273px;
        height: 155px;
        margin-left: 26px;
        border: 2px solid #00539F;
        .video-footer-player-delta-span {
           position: absolute;
           bottom: -25px;
        }
      }
    }
  }
}
