.registration-tax {
  width: 100%;
  .ant-form-item-label {
    width: 220px;
  }
}

.registration-bottom {
  padding-top: 11px;
}
.shop-text {
  color: rgba(0, 0, 0, 0.45);
}
