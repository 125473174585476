.user_serch_invoice {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 24px 24px;
  .user_serch_icon {
    position: absolute;
    top: 30px;
    right: 25px;
    width: 50px;
  }
  .user_serch {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }
}
.listItem {
  cursor: pointer;
  margin-right: 36px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.activeListItem {
  background: #4096ff;
  color: #fff;
  padding: 4px 8px;
  margin-right: 36px;
  cursor: pointer;
}
.default_item {
}
.line {
  width: 100%;
  height: 1px;
  background-color: #e5e6eb;
  margin: 19px 0 24px;
}
