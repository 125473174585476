
.rateSetting {
  .RateSetting_Collapse_time {
    padding-left: 10px;
  }
  .rateSetting-component {
    display: flex;
    width: 100%;
    flex: 1;
    .incomeTaxRateReverse {
      .ant-form-item-label {
        min-width: 240px;
      }
    }
  }
  .rateSetting-order {
    display: flex;
    justify-content: space-between;
    .rateSetting-order-text {
      width: 250px;
      height: 32px;
      background: #FFFBEB;
      border: 1px solid #FAAD1459;
      text-align: center;
      line-height: 32px;
      color: #FF7300D9;
    }
  }
  .rateSetting-text {
    font-weight: 500;
    font-size: 16px;
    color: #000;
  }
  .rateSetting-specification {
    min-height: 106px;
    padding: 16px 24px 16px 24px;
    border-radius: 2px 0px 0px 0px;
    background: #E6F1FF80;
  }
}
.rateSetting-radio {
  .ant-radio {
    margin-top: -12px;
  }
}
