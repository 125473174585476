
.information-content {
  padding: 16px 24px;
  .information-message {
    display: flex;
    height: 110px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }
    .info_items {
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
    }
    .label {
      color: rgba(0, 0, 0, 0.51);
    }
    .value {
      padding-left: 8px;
    }
  .order-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .apply-billing-button {
      margin-top: 24px;
      color: rgba(24, 144, 255, 1);
    }
  }
}
.information-text {
  height: 30px;
  border-radius: 2px;
  line-height: 30px;
  background: rgba(255, 251, 230, 1);
  border: 1px solid rgba(255, 229, 143, 1);
  color: rgba(0, 0, 0, 0.85);
  padding: 0 10px;
  margin-top: 12px;
}
.table {
  padding-top: 10px;
  .tr-toolbar {
    margin-bottom: -50px;
  }
  .tr-table-wrapper {
    padding: 0 !important;
  }
}
.information-form {
  padding-top: 16px;
  .ant-form-item-label {
     width: 66px;
  }
  .ant-input {
    border-radius: 0;
  }
}
