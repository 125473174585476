.card-info {
  padding: 0px 22px;
  flex: 1;
  background: white;

  .content {
    position: relative;
    padding: 16px 0 0 0;
    display: flex;

    .left {
      width: 50%;
      white-space: nowrap;

      .label {
        color: #00000073;
      }

      .value {
        margin-top: 20px;
        font-weight: 700;
        font-size: 32px;
      }
    }

    .title {
      position: absolute;
      right: 0;
      color: #00000073;
    }

    .right {
      padding-top: 12px;
      flex: 1;
    }
  }

  .bottom {
    border-top: 1px solid #0000000f;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    padding: 8px 0;
    align-items: center;

    span {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 8px;
    }

    .add-status {
      border-bottom: 8px solid #52c41a;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }

    .decline-status {
      border-top: 8px solid #ff4d4f;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}
