.ledger-checkbox {
  padding: 16px;
}
.ledger {
  .list-summary-information {
    position: absolute;
    left: calc(50% - 20px);
    transform: translateX(-49%);
    width: calc(100% - 100px);
    height: 40px;
    padding-left: 10px;
    margin-top: 24px;
    line-height: 40px;
    background: rgb(24 144 255 / 10%);
    border: 1px solid rgb(24 144 255 / 50%);
    border-radius: 2px;
    font-size: 14px;
    display: flex;
    span {
      width: 300px;
    }
  }

  @media screen and (max-width: 1276px) {
    .list-summary-information {
      top: 320px;
      width: 92%;
    }
  }

  .ant-spin-nested-loading {
    margin-top: 40px;
  }
}
