.mar_left_40 {
  margin-left: 80px;
}
.text-add {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
}
.ipt_col {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  margin-left: 70px;
  width: 800px;
  min-height: 50px;

}
.user-crud {
  position: relative;

  .user-crud-span {
    position: absolute;
    top: 5px;
    left: 200px;
  }
}
.span_color {
  color: rgba(0, 0, 0, 0.45);
}
