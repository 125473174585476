.formClass {
  padding-top: 20px;
  margin-right: 40px;
}

.DetailModule_list_keysWrod {
  display: flex;
  width: 100%;
  height: 80px;
  background: #fff;
  align-items: center;
}
