.procure_conttainer {
  padding: 0 24px 20px;
  width: 100%;
  height: calc(100vh - 48px);
  overflow-y: auto;
  background-color: rgba(240, 242, 245, 1);
  overflow-x: hidden;
  .procure_list_title {
    display: flex;
    justify-content: space-between;
  }
  .search_container {
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
  }
  .procure_list {
    padding: 20px 24px;
    border-radius: 2px;
    background-color: #fff;
    .procure_list_title {
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.PlatformSetup_user {
  width: 100%;

  background: #fff;

  .PlatformSetup_user_basie {
    width: 100%;

    padding: 16px 16px 0 16px;
    .PlatformSetup_user_basie_display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .PlatformSetup_user_basic_div {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}

.formClass {
  padding-top: 24px;
  margin-right: 40px;
}

.user_serch {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #fff;
}
