.field-content-home-table {
  height: 65vh;
  overflow: scroll;
}
.files_container {
  padding: 20px;
  background-color: #f0f2f5;
  width: calc(100% - 300px);
  height: calc(100vh - 50px);
  overflow: hidden;
  .content {
    padding: 20px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    .create_button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
    }
  }
}
