.newLogout-from {
  .ant-card-bordered {
    border: none;
    border-radius: 0;
  }
}
.cardButton {
  width: 100%;
  border-top:  1px solid #f0f0f0;
  .ant-card-body {
    text-align: center;
  }
}
.logout_files {
  position: relative;
  .logout_files_text {
    position: absolute;
    left: 130px;
    top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
  }
}
.files_span {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
  }
}

 .logout_checkbox {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 40px;
   .checkbox {
     margin-right: 8px;
   }

   span {
     color: #1677FF;
   }
 }
