.wrapper {
  margin-right: 12px;

  & > div {
    background: white;

    .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #0000000f;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .filter {
        display: flex;

        > div {
          margin: 0 12px;
          cursor: pointer;
        }

        .checked {
          color: #1890ff;
        }
      }
    }
  }
}
.echarts-contain {
  padding: 17px 24px 24px 24px !important;
}
