.invoces_info_container{
    padding: 6px 24px 24px;
    width: calc(100vw - 250px);
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(240, 242, 245, 1);
    .invoces_info_content{
        border-radius: 2px;
        background-color: #fff;
        margin-bottom: 16px;
        .invoces_info_content_title{
            padding: 16px 24px 16px 24px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-weight: 500px;
            line-height: 24px;
            border-bottom: 1px solid
rgba(233, 233, 233, 1);
            .invoces_info_content_title_desc{
                border-left: 1px solid rgba(0, 0, 0, 0.15);
                margin-left: 16px;
                padding-left: 16px;
                font-weight: 400;
                font-size: 14px;

            }
        }
        .invoces_info_content_html{
            padding: 16px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .details_task_status_content{
                color: rgba(0, 0, 0, 0.85);
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                .details_task_status{
                    margin-left: 8px;
                    padding: 0px 8px;
                    display: flex;
                    align-items: center;
                    height: 26px;
                    .details_task_status_icon{
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }
                .warning_bg{
                    border: 1px solid #FDEECE;
                    background-color:#FEF7E7 ;
                }
                .primary_bg{
                    background-color: rgba(15, 118, 245, 0.1);
                    border: 1px solid rgba(15, 118, 245, 0.05);
                }
                .success_bg{
                    background-color: rgba(0, 209, 139, 0.1);
                    border: 1px solid rgba(0, 209, 139, 0.05);
                }
                .error_bg{
                    background-color: rgba(255, 139, 139, 0.1);
                    border: 1px solid rgba(255, 167, 167, 0.05);
                }
                .warning{
                    background-color:#FAAB0C;
                }
                .success{
                    background-color: #00D18B;
                }
                .primary{
                    background-color: #0F76F5;
                }
                .error{
                    background-color: red;
                }
            }

        }
        .invoces_info_content_text{
            padding: 16px 24px;
            .text_title{
                margin-bottom: 8px;
                color: #000;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
            .invoicing_information_container{
                color: rgba(0, 0, 0, 0.5);
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                .invoicing_information_container_value{
                    color: rgba(0, 0, 0, 0.85);
                }
            }
        }
    }
    .mar_top_16{
        margin-top: 16px;
    }
    .mar_btm_16{
        margin-bottom: 16px;
    }
    .pad_top_16{
        padding-top: 16px;
    }
    .flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
