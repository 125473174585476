.fileDomeCile {
  display: flex;
  .fileDomeCile-file {
    display: flex;
    flex-direction: column;
    text-align: left;
    .fileDomeCile-file-span {
      position: relative;
      color: #1890ff;
      cursor: pointer;
      padding-top: 2px;
      width: 500px;
    }
  }
}

.materialUploadDetails-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
}

.materialUploadDetails_row {
  padding-top: 15px;
}
.fileDomeCile-icon {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.fileDomeCile-file-span:hover {
  background: rgb(245, 245, 245);
  .fileDomeCile-icon {
    opacity: 1;
  }
}
.details-img {
  padding-left: 10px;
  .details-img-span {
    padding-left: 40px;
  }
}
.details-file {
  padding-top: 10px;
  padding-left: 7px;
  margin-bottom: 10px;
}
.flex_phone {
  padding-top: 10px;

}
