.invoices_container{
    background-color: rgba(240, 242, 245, 1);
    .invoices_search{
        background-color: #fff;
    }
    .invoices_table_container{
        margin-top: 16px;
        padding: 0 24px 24px;
        background-color: #fff;
        border-radius: 2px;
        .invoices_table_container_title{
            padding: 16px 0;
        }
    }
    .invoces_container_list_status{
        display: flex;
        align-items: center;
        .default_icon_span{
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 8px;
        }
         .warning{
            background-color:#FAAB0C;
        }
        .success{
            background-color: #00D18B;
        }
        .primary{
            background-color: #0F76F5;
        }
        .error{
            background-color: red;
        }
    }

}

.code-modal {
  text-align: center;
  .invoicing-img {
    padding: 20px 0 10px 0 ;
  }
}
.procure-information {
  height: 40px;
  margin-top: 0;
  line-height: 40px;
  border-radius: 2px;
  background: rgba(24, 144, 255, 0.1);
  border: 1px solid rgba(24, 144, 255, 0.5);
  padding: 0 10px;
  display: flex;
  span {
    display: inline-block;
    width: 300px;
  }

}
.purchase-order-page {
  .ant-pro-table-alert-info {
    color: #000;
  }
  .ant-pro-table-alert {
    background-color: transparent;
  }
  .ant-pro-table-alert-container {
    padding-block: 0;
    padding-inline: 0;
  }
  .ant-pro-table-alert-info-option {
    display: none;
  }
}
.invoicing {
  .detail-page-header-pane {
    height: 60px;
    position: relative;
  }

  .invoicing-tabs {
    position: absolute;
    left: -30px;
    top: 40px;
    z-index: 99;
  }
}
