.TwoCodeModal {
  .main {
    width: 100%;
    padding: 16px;

    .title {
      font-size: 22px;
      color: #000;
    }
  }
}
