.RequestBiling {
  position: relative;
  .ant-form-item-label {
    width: 240px;
  }
}
.NewTradingScheme_Content_seller_remove {
  padding-top: 11px;
  margin-bottom: 24px;
}
.remarks {
  position: absolute;
  left: 500px;
  top: 0px;
  .ant-form-item-label {
    width: 85px;
  }
  .ant-checkbox-group {
    display:block
  }
  .ant-form-item{
    margin-bottom : 0;
  }
  .ant-checkbox-wrapper {
    margin-top: 6px;
  }
  .ant-input {
    z-index: 99;
  }
}
