.tag-custom {
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 2px;

  &.tag-custom-size-large {
    line-height: initial;

    .ant-badge-not-a-wrapper {
      display: flex;
      align-items: center;
    }

    .ant-badge-status-dot {
      top: 0;
    }

    .ant-badge-status-text {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &.tag-custom-default {
    background-color: #f4f7fc;
    border-color: #e9edf6;

    .ant-badge-status-dot {
      background-color: #c2c6cc;
    }
  }

  &.tag-custom-processing {
    background-color: #e7f1fe;
    border-color: #d9ecff;

    .ant-badge-status-dot {
      background-color: #007af5;
    }
  }

  &.tag-custom-success {
    background-color: #e5faf3;
    border-color: #d0f5e9;

    .ant-badge-status-dot {
      background-color: #00d18b;
    }
  }

  &.tag-custom-error {
    background-color: #fee9e7;
    border-color: #ffdfdb;

    .ant-badge-status-dot {
      background-color: #f53f3f;
    }
  }

  &.tag-custom-warning {
    background-color: #fef7e7;
    border-color: #fdeece;

    .ant-badge-status-dot {
      background-color: #faab0c;
    }
  }

  &.tag-custom-no-bg-color {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-color: transparent;
  }
}
