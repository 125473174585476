.right {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
}
.template_primaryS {
  width: 100%;
  height: 50px;
  background: #fff;
  margin-top: 10px;
}
