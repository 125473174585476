.setup_tissue_user {
  width: calc(100vw - 208px);
  height: 100%;
  padding: 20px;
  display: block;
  position: relative;
  background-color: #f0f2f5;

  .content {
    background-color: #fff;
    height: calc(100vh - 100px);
    padding: 20px;
  }

  &-add {
    margin-bottom: 15px;
  }

  .system-user-add {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-pro-query-filter-row-split:last-child {
  margin-block-end: 0;
}
.tissue {
  .detail-page-header-pane {
    height: 60px;
    position: relative;
  }
  .tissue-tabs {
    position: absolute;
    left: -30px;
    top: 40px;
    z-index: 99;
  }
}
