$starSize: 16px;
$starContainerPadding: 6px;
$starContainerW: $starSize * 2 + $starContainerPadding * 2;
.imageItemWrapper {
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  .actionsBar {
    z-index: 999;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%) rotateZ(45deg);
    transition: 0.25s;
    background-color: #1677ff;
    height: $starContainerW;
    width: $starContainerW;
    .markSty {
      cursor: pointer;
      transform: translate(0, -50%);
      position: absolute;
      left: $starContainerPadding / 2 - 1;
      top: 50%;
      font-size: $starSize;
    }
  }
}

.uploadDraggerContainer {
  .ant-spin-nested-loading {
    display: inline-block;
  }
  .ant-upload.ant-upload-drag {
    width: 300px;
    height: 300px;
    display: inline-block;
    background-color: transparent;
  }
  .ant-upload.ant-upload-btn {
    padding: 16px;
  }
}
