.new-order-detail-page {
  &-status-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 8px;
    .ant-tag {
      margin-right: 0;
    }
  }
  &-status-label {
    white-space: nowrap;
    font-size: 20px;
    line-height: 28px;
    color: #000000d9;
  }
}

.ant-form-item-label {
  width: 120px;
}

.cardButton {
  .ant-card-body {
    text-align: center;
  }
}

.newApplication-from {
  .ant-card-bordered {
    border: none;
    border-radius: 0;
  }
}
