.platformSetup-container {
  width: 100vw;
  height: 100vh;

  &-content {
    height: calc(100% - 48px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.ant-btn {
  border-radius: none;
}
