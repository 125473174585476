.contract_template_create {
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-card {
    border-radius: 0;
    box-shadow: none;
  }
}
