.NewTradingScheme_Content_seller_titleText {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
}
.NewTradingScheme_Content_seller_formItem {
  padding-top: 10px;
}
.NewTradingScheme_Content_seller_toip {
  color: rgba(0, 0, 0, 0.45);
  padding-left: 0px;
}
.NewTradingScheme_Content_seller_remove {
  .ant-row .ant-form-item-row {
    .ant-col .ant-form-item-label {
      opacity: 0;
    }
  }
}
