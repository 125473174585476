.pdf-view-wrapper {
  .ant-modal-body {
    padding: 10px;
    .pdf-view {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      height: 70vh;
      padding: 30px 0 50px;
      overflow: hidden;
      .container {
        box-sizing: border-box;
        min-width: 680px !important;
        max-width: 680px;
        margin: 0 auto;
        overflow: scroll;
        box-shadow: rgb(0 0 0 / 20%) 0 2px 4px 0;
        .react-pdf__Document {
          overflow-y: scroll;
          .react-pdf__Page__textContent {
            display: none;
          }
        }
      }
      .page-tool {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-right: 15px;
        padding-left: 15px;
        color: white;
        background: rgb(66 66 66);
        border-radius: 19px;
      }
      .page-tool-item {
        padding: 8px 15px;
        padding-left: 10px;
        cursor: pointer;
      }
      .downbtn {
        margin-left: 40px;
        color: #1681ff;
      }
      input {
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-right: 10px;
        color: black;
        text-align: center;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: none;
      }
      input[type="number"] {
        appearance: textfield;
      }
    }
  }
}
