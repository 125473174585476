.editableImageContainer {
  :global {
    img {
      min-width: 104px;
      min-height: 104px;
    }
  }
}

.introduce {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.businessLicenseedit {
  position: absolute;
  top: 42%;
  left: 35%;
}

.idCardFrontedit {
  position: absolute;
  top: 40%;
  left: 32%;
}

.idCardBackedit {
  position: absolute;
  top: 40%;
  left: 32%;
}
