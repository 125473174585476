.order-payment-pane-amount-cards {
  display: flex;
  .amount-card- {
    flex: 1;
  }
  .ant-divider.ant-divider-vertical {
    height: auto;
    margin-left: 26px;
    margin-right: 26px;
  }
}
.page-detail-payment-info-item {
  .page-detail-payment-info-item-title {
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    text-align: left;
  }
  .page-detail-payment-info-item-content {
    display: flex;
    > div {
      min-width: 150px;
      margin: 12px 36px;
      margin-left: 0;
      > span {
        color: rgb(0 0 0 / 65%);
      }
    }
  }
}
