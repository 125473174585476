.top-navbar {
  width: auto;
  padding-left: 0;
  height: 48px;
  background-color: #001529;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .nav_list_content {
    margin-left: -20px;
    max-width: calc(100vw - 350px);
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
  }
  a {
    display: block;
    width: 120px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    float: left;
    color: white;
    font-size: 16px;

    &:link {
      color: #fff;
      text-decoration: none;
    }

    &:active {
      color: none;
    }

    &:visited {
      color: #fff;
      text-decoration: none;
    }

    &:hover {
      color: #1890ff;
      text-decoration: none;
    }
  }

  .navbar-link {
    display: flex;
    flex-flow: column nowrap;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    &.active,
    &:hover {
      border-bottom: 3px solid #1890ff;
      font-weight: 500;
    }
  }

  .logo {
    width: 270px;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding-right: 80px;
    > p {
      margin: 0;
      font-size: 16px;
    }

    > img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
      border-radius: 50%;
    }
  }
}

.user_info {
  position: relative;
  margin-right: 10px;

  cursor: pointer;
  display: flex;
  align-items: center;

  .user_logo {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .user_name {
    margin-left: 10px;
    color: #fff;
  }

  .user_children {
    position: absolute;
    top: 30px;
    left: 0;
    display: none;
    z-index: 99;

    .user_children_item {
      background-color: #fff;
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      box-shadow: 0px 0px 10px rgba(#494949, 0.3);
    }
  }

  &:hover {
    .user_children {
      display: block;
    }
  }
}

.user_info_change {
  color: #fff;
  margin-right: 20px;
  font-size: 14px;
}

.ant-dropdown.ltd-drop-down {
  ul {
    max-height: 200px;
    overflow-y: auto;

    > li:hover {
      background: #ecf5ff;
      color: #1890ff;
    }
  }
}

.ant-modal-confirm-body-wrapper {
  padding: 24px !important;
}
