.NewTradingScheme_Extra_button {
  padding-right: 20px;
  .NewTradingScheme_Extra_button_save {
    margin-left: 10px;
  }
}
.ant-collapse-header-text {
  font-weight: 600;
  font-size: 16px;
}
.NewTradingScheme_Content {
  width: 100%;
  min-height: 500px;

  .ant-form-item-label {
    width: 150px;
  }
}
.NewTradingScheme_Collapse {
  margin-top: 10px;
}
.ant-collapse-header {
  background: #ffffff;
}
.NewTradingScheme_Content_button_save {
  width: 100%;
  display: flex;
  padding-right: 20px;
  height: 56px;
  background: #fff;
  margin-bottom: -20px;
  align-items: center;
  justify-content: center;
  .NewTradingScheme_Content_button_save_left {
    margin-right: 10px;
  }
}
.ant-form-item {
  margin-bottom: 13px;
}
