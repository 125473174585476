.App {
  width: 100vw;
  height: 100vh;
}

.query_header {
  background-color: #fff;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 1px;
  width: 100%;
  margin-bottom: 14px;
}

.table_title {
  font-family:
    PingFang SC,
    serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.table_content {
  overflow-y: auto !important;
  .ant-form-item-control-input {
    width: 240px;
  }

  .table_content_list {
    background-color: #fff;
    padding: 24px;
  }
}

.common_title {
  line-height: 56px;
  font-family: "PingFang SC", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 24px;
}

.form_item_content {
  width: 364px;
}

.form_control {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-top: 1px solid #e9e9e9;
}

.ant-form-item-label {
  width: 110px;
}
.ant-btn {
  border-radius: 0;
}

.ant-modal-header {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 16px 24px !important;
}
.ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 0;
}
.commonality {
  .ant-modal .ant-modal-content {
    padding: 24px;
    border-radius: 0;
  }
}
.ant-menu {
  width: 208px;
}

.ant-table-thead {
  height: 47px !important;
}

.ant-pagination {
  height: 32px;
  display: flex;
  align-items: center;
}
.tr-single-tab {
  font-weight: 600 !important;
}

.tr-table-wrapper {
  padding: 0 24px 0 !important;
}

.fr-search {
  padding: 24px 24px 5px 0 !important;
  .ant-form-item {
    margin-bottom: 20px;
  }
  // 特殊处理筛选项超出7个消失问题
  .fr-field-visibility {
    width: 100%;
    height: 100%;
    visibility: visible;
    position: static;
    opacity: 1;
  }
}

.menu-icon-image {
  height: 18px;
}
