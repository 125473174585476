
.basic_input {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-wrap: wrap;
}
._type_input {
  padding: 24px;
}

.NewTradingScheme_Content_button_save {
  width: 97.2%;
  margin-left: 24px;
  .NewTradingScheme_Content_button_save_left {
    margin-right: 10px;
  }
}

.ant-card-bordered {
  border-radius: 0px;
}
.scheme-payment {
  // padding-left: 24px;
  .ant-form-item-label {
    width: 220px;
  }
}
