.cropper- {
  &initWrap {
    border-radius: 5px;
    border: 1px dashed #818181;
    :global {
      .cropper-container {
        img {
        }
      }
    }
  }

  &wrap {
    border-radius: 5px;
    border: 1px dashed #818181;
  }

  &action {
    margin-top: 20px;
    display: flex;
  }

  &action > div:first-child {
    margin-right: 10px;
    img {
      display: block;
      width: 200px;
      height: 150px;
    }
  }

  &button {
    margin-top: 25px;
    :global {
      .ant-upload-list-item-info {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    button {
      display: inline-block;
      margin: 5px 5px 10px;
    }
  }
}
.cropper_modal {
  .ant-modal-content {
    padding: 16px !important;
  }
}
