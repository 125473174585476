.DetailModule_list_title {
  display: flex;
  justify-content: space-between;
}

.search_container {
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
}

.DetailModule_list {
  padding: 16px 24px 0px 24px;
  border-radius: 2px;
  background-color: #fff;

  .DetailModule_list_title {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
  }
}

.DetailModule_list_keysWrod {
  display: flex;
  width: 100%;
  height: 80px;
  background: #fff;
  align-items: center;
}

.addModule {
  padding-top: 3px;
}
