.out_employed{
    background-color: #fff;
    border-radius: 2px;
    .out_employed_title{
        padding: 16px 0 16px 24px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        box-shadow: inset 0px -1px 0px #E9E9E9;
    }
    .out_employed_table{
        padding:24px ;
        .tr-toolbar {
          height: 0;
        }
    }
}
