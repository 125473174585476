.DetailModule_list_keysWrod {
  display: flex;
  width: 100%;
  height: 80px;
  background: #fff;
  align-items: center;
}
.addModule {
  padding-top: 3px;
}
