.form_item {
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 18px;

  .form_item_label {
    min-width: max-content;
    color: rgba(0, 0, 0, 0.6);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
  }
}
.financing-space {
  position: relative;
  .financing-space-tag{
    position: absolute;
    left: 100px;
    top: 17px;
  }
}
.details_primary {
  width: 100%;
  height: 50px;
  background: #fff;
  line-height: 50px;
}
