.applicationDetails {
  position: relative;
  min-width: 350px;
  display: flex;
  justify-content: right;
  .details-submitted {
    position: absolute;
    display: flex;
    right: 0;
    top: -30px;
    justify-content: flex-end;
  }
}

.check-modal {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-top: 11px;
  margin-right: 10px;
}
.modal-details {
  padding: 10px;
}
.check-modal-button {
  margin-right: 10px;
}
.details-form {
  padding: 10px;
}
.detail-license {
  padding-left: 16px;

  .ant-form-item-label {
    width: 138px;
  }
}
